import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.jsx";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/web/inludes/general/ScrollToTop";

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </Provider>
    </Router>,
    document.getElementById("steyp")
);
